





import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import EventForm from '@/event/EventForm.vue';
import EditorMode from '@/common/editor.mode';

export default Vue.extend({
  components: { Scaffold, EventForm },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    mode: {
      type: EditorMode,
      default() {
        return EditorMode.edit;
      },
    },
  },
});
